.company-logo {
  padding-left: 25px;
  padding-top: 25px;
}

.login-welcome-text {
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  line-height: 32px;
}

.login-error-text {
  color: #b60909;
  font-family: Roboto;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 0px;
}

.login-error-text-secondary {
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
}

.login-form-controls {
  padding: 24px 50px 50px 50px;
}

.reset-form-controls {
  padding: 25px 50px 50px 50px;
}

.login-input {
  height: 40px;
}

.login-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  /*margin-top: -250px;
	margin-left: -225px;*/
  transform: translate(-50%, -50%);
  height: auto;
  width: 450px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 5px 0 #000000;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
  height: 44px;
  font-size: 16px;
}

.reset-status-message-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
}

.reset-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  /*margin-top: -250px;
	margin-left: -225px;*/
  transform: translate(-50%, -50%);
  height: auto;
  width: 450px;
  background-color: #f9f9f9;
  box-shadow: 1px 1px 5px 0 #000000;
}

.link {
	font-family: "Verdana" sans-serif;
	font-size: 1em;
	text-align: left;
	color: #1890ff;
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	cursor: pointer;
}

.validation-passed {
  color: green;
}

.validation-failed {
  color: #b60909;
}

.input-confirm-password {
  margin-bottom: 10px;
}

.button-reset {
  margin-bottom: 10px;
}

.reset-request {
  font-weight: bold;
  color: green;
}

.invalid-token {
  color: #b60909;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
}

.root .MuiDialogContent-root {
  padding: 0;
}
