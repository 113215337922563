html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
  min-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
